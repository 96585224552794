import {createRouter, createWebHistory} from "vue-router";

const routes = []

const router = createRouter({
    scrollBehavior(to, from, savedPosition) {
        let dropdown = document.querySelector("#navbarNav")
        if(dropdown){
            dropdown.classList.remove("show")
        }
        if (savedPosition) {
            return savedPosition;
        } else {
            return {top: 0};
        }
    },
    history: createWebHistory('/'),
    linkActiveClass: "active-link",
    routes,
});

export default router;
