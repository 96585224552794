import "bootstrap"
import "./assets/style.scss"
import "./assets/custom.css"
import "./assets/cookieAgree.css"

import {createApp} from "vue"
import App from "./App.vue"
import router from "./router"

import axios from "axios"

let sessionHashName = "OITHSessionHash"
let isLocalHost = false
let webPageUrl = "https://otpbankit.hu/"
let accountUrl = "https://account.ecosim.hu/"
let ecoSimApiUrl = "https://api.ecosim.hu/"
let baseUrl = process.env.VUE_APP_API_PATH

if(window.location.href.includes("localhost") || window.location.href.includes("test")){
    sessionHashName = sessionHashName + 'Test'
    webPageUrl = "https://test.otpbankit.hu/"
    accountUrl = "https://account-test.ecosim.hu/"
    ecoSimApiUrl = "https://api-test.ecosim.hu/"
}
if(window.location.href.includes("localhost")){
    isLocalHost = true
    webPageUrl = "http://localhost:5174/"
    accountUrl = "http://localhost:5173/"

    //GET FROM API VUE_APP_API_PATH
    const apiUrl = process.env.VUE_APP_API_PATH+'/api/v1/development/serverData?t='+process.env.VUE_APP_API_TOKEN
    //FETCH CONTENT AND UPDATE SERVER DATA
    fetchContentJson(apiUrl).then((data) => {
        serverData = data
    })
    //TIMEOUT FOR DEVELOPMENT
    setTimeout(function(){
        const app = createApp(App,{serverData: serverData})

        axios.defaults.baseURL = baseUrl
        axios.defaults.withCredentials = true
        app.config.globalProperties.$axios = axios
        axios.defaults.headers.common = {
            "Authorization": `Bearer ${localStorage.getItem(sessionHashName)}`
        };

        app.config.globalProperties.$sessionHashName = sessionHashName
        app.config.globalProperties.$isLocalHost = isLocalHost
        app.config.globalProperties.$webPageUrl = webPageUrl
        app.config.globalProperties.$accountUrl = accountUrl
        app.config.globalProperties.$ecoSimApiUrl = ecoSimApiUrl
        app.config.globalProperties.$baseUrl = baseUrl

        //DYNAMIC ROUTES
        dynamicRoutes()
        app.use(router, axios)
        app.mount("#app")
    }, 1000)
}else{
    serverData = JSON.parse(atob(serverData))
    const app = createApp(App,{serverData: serverData})

    axios.defaults.baseURL = baseUrl
    axios.defaults.withCredentials = true
    app.config.globalProperties.$axios = axios
    axios.defaults.headers.common = {
        "Authorization": `Bearer ${localStorage.getItem(sessionHashName)}`
    };

    app.config.globalProperties.$sessionHashName = sessionHashName
    app.config.globalProperties.$isLocalHost = isLocalHost
    app.config.globalProperties.$webPageUrl = webPageUrl
    app.config.globalProperties.$accountUrl = accountUrl
    app.config.globalProperties.$ecoSimApiUrl = ecoSimApiUrl
    app.config.globalProperties.$baseUrl = baseUrl

    //DYNAMIC ROUTES
    dynamicRoutes()
    app.use(router, axios)
    app.mount("#app")
}


async function fetchContentJson(apiUrl){
    const response = await fetch(apiUrl)
    const serverData = await response.json()
    console.log(serverData.data)
    return serverData.data
}

function dynamicRoutes(){
    const routeData = serverData.routes
    for(let i=0;i<routeData.length;i++){
        const paths = routeData[i]['paths']
        const name = routeData[i]['name']
        //LOOP BY KEY AND VALUE
        for (const [key, value] of Object.entries(paths)) {
            const language = key
            const path = value
            const languageName = name+'_'+language
            router.addRoute(
                {
                    path: path,
                    name: languageName,
                    component: () => import('./views/'+routeData[i]['component']+'.vue'),
                    props: {'pageId' : name}
                }
            )
        }

    }
}